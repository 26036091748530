<template></template>

<script>
import appUtils from "../../utils/appUtils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "AutoLogin",
  data() {
    return {};
  },
  mounted() {
    const token = this.$route.query.token;
    const org_id = this.$route.query.org_id;
    const ws_id = this.$route.query.ws_id;

    if (!token || (!org_id && !ws_id)) {
      this.handleLoginFail();
      return;
    }

    appUtils.setLocalToken(token);
    this.$store.commit("authenticate/setToken", token);

    this.handleAutoLogin();
  },
  methods: {
    async handleAutoLogin() {
      try {
        const response = await this.$rf.getRequest("AuthRequest").goMe();

        const userInfo = response?.data?.data || {};
        const newUserInfo = {
          ...userInfo,
          supplierInfo: userInfo?.suppliers?.find(
            (item) => item?.org_id === Number(this.$route.query.org_id)
          ),
          processorsInfo: userInfo?.processors?.find(
            (item) => item?.org_id === Number(this.$route.query.org_id)
          ),
        };
        const isOwnerOrAdmin = [1, 2].includes(
          newUserInfo?.supplierInfo?.role || newUserInfo?.processorsInfo?.role
        );
        if (!newUserInfo || !isOwnerOrAdmin) {
          this.handleLoginFail();
          return;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("lbl_login_successfully"),
              icon: "CheckIcon",
              variant: "success",
            },
          });

          this.$store.commit("authenticate/setUserLogin", newUserInfo);
          appUtils.setLocalUser(JSON.stringify(newUserInfo));
          this.$router.replace({ name: "home" }).catch((error) => {});
        }
      } catch (error) {
        console.log(error);
        this.handleLoginFail();
      }
    },
    handleLoginFail() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("lbl_something_went_wrong_when_login"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
      appUtils.removeLocalUser();
      appUtils.removeLocalToken();

      this.$router.push({ path: "/login" });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
